.page-slider-wrapper {
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  overflow: hidden;
}

.slider-wrapper-background, .fixed, .carousel {
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.carousel-indicators {
  position: absolute;
  top: 10px;
  right: 1rem !important;
  left: unset;
  bottom: unset;
  display: flex;
  flex-direction: row;
  gap: 14px;
  justify-content: end !important;
  text-align: right !important;
  line-height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
  height: 20px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.carousel-indicators button {
  height: 10px !important;
  min-height: 10px !important;
  max-height: 10px !important;
  line-height: 10px !important;
  width: 10px !important;
  max-width: 10px !important;
  min-width: 10px !important;
  padding: 0 !important;
  background-color: grey;
  border: 1px solid transparent;
  border-radius: 50%;
  margin: 0 !important;
}
.carousel-indicators button.active {
  background-color: var(--main-link-color);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.carousel-inner, .carousel-item {
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.carousel-item {
  position: relative;
}

.slide-carousel-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  border: 1px solid transparent;
}
.slide-carousel-image img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.carousel-caption {
  background-color: rgba(2, 2, 2, 0.4);
  position: absolute;
}
.carousel-caption h5 {
  text-align: center;
  text-transform: uppercase;
  color: var(--main-link-color);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.carousel-caption p {
  text-align: justify;
  padding: 1rem 0 1rem 0;
  margin: 1.5rem;
  border-style: double;
  border-color: rgba(255, 255, 255, 0.8);
  color: white;
  font-size: 1rem;
  line-height: 1.6rem;
  border-left: none;
  border-right: none;
}

.slide-item-icons {
  display: flex;
  gap: 1rem;
  margin: 0 auto;
  padding: 0;
  justify-content: center;
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  flex-direction: row;
}
.slide-item-icons li.slider-item-icon {
  font-size: 2.6rem !important;
}
.slide-item-icons li.slider-item-icon-even {
  color: var(--main-link-color);
}
.slide-item-icons li.slider-item-icon-odd {
  color: white;
}

.slide-item-desc-read-more {
  margin-top: 40px;
  text-align: center;
}
.slide-item-desc-read-more .btn:hover {
  color: white;
  transform: scale(0.9);
}
.slide-item-desc-read-more .btn:hover > * {
  color: white;
}

#software_image_slider_section {
  background-size: cover;
}
#software_image_slider_section.android-application {
  background-image: url("../../../../medias/software/android_app_design.png");
}
#software_image_slider_section.desktop-application {
  background-image: url("../../../../medias/software/desktop_app_design.png");
}
#software_image_slider_section.website-application {
  background-image: url("../../../../medias/software/web_app_design.png");
}

#software_slider_background {
  min-width: unset;
  max-width: 1200px;
  width: 100%;
  background-color: rgba(64, 76, 92, 0.5);
}

#product_technology_list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
#product_technology_list .technology-icon {
  font-size: 2rem;
}

@media screen and (max-width: 480px) {
  .page-carousel-section, .page-slider-wrapper {
    min-height: 400px !important;
    max-height: 400px !important;
    height: 400px !important;
  }
  .carousel-caption {
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 1rem;
  }
  .carousel-caption .slide-item-icons {
    left: unset;
  }
  .carousel-caption p, .carousel-caption .slide-item-desc-read-more {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 576px) {
  .page-carousel-section, .page-slider-wrapper {
    min-height: 480px !important;
    max-height: 480px !important;
    height: 480px !important;
  }
  .carousel-caption {
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 1rem;
  }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
  .page-carousel-section, .page-slider-wrapper {
    min-height: 520px !important;
    max-height: 520px !important;
    height: 520px !important;
  }
  .carousel-caption {
    top: 0;
    right: unset;
    bottom: 0;
    left: 5%;
    width: 35%;
  }
  .carousel-caption h5 {
    margin: 50px 0 30px;
  }
  .carousel-caption p {
    margin-bottom: 30px;
  }
  .carousel-caption .read-more-icon {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .page-carousel-section, .page-slider-wrapper {
    min-height: 600px !important;
    max-height: 600px !important;
    height: 600px !important;
  }
  .carousel-item-left .carousel-caption {
    right: unset;
    left: 5%;
  }
  .carousel-item-right .carousel-caption {
    right: 5%;
    left: unset;
  }
  .carousel-caption {
    top: 0;
    bottom: 0;
    width: 35%;
  }
  .carousel-caption h5 {
    margin: 50px 0 30px;
  }
  .carousel-caption p {
    margin-bottom: 30px;
  }
  .slide-item-desc-read-more .read-more-icon {
    display: none;
  }
}
#appointment_section .section-container {
  background-color: var(--card-background-color);
  border-radius: 1rem;
  padding: 1rem;
}
#appointment_section .section-container .section-description {
  color: var(--forground-color);
  padding: 1rem !important;
}

#appointment_form_container {
  display: grid;
  gap: 1.4rem;
  min-width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  width: calc(100% - 2rem);
  margin: 0 auto !important;
}
#appointment_form_container textarea {
  min-height: 100px;
  height: 100px;
}
#appointment_form_container .form--widget {
  padding-left: 0.825rem !important;
  padding-right: 0.825rem !important;
}

#appointment_form_wrapper {
  height: fit-content;
  margin-top: 1rem;
  text-align: center;
}
#appointment_form_wrapper form {
  height: fit-content;
  margin: 0 auto;
  min-width: 80%;
  max-width: 80%;
  width: 80%;
}

@media screen and (max-width: 576px) {
  #appointment_form_wrapper .form--widget {
    margin-bottom: 1rem;
  }
  .appointment-submit-row {
    width: 100%;
    text-align: center;
    margin-top: 35px;
  }
}
@media screen and (min-width: 576px) {
  #appointment_form_wrapper .form-widget {
    min-width: 90%;
    max-width: 90%;
    width: 90%;
    margin: 0 auto !important;
  }
  #appointment_form_wrapper form textarea {
    margin-left: 0 !important;
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
  #appointment_form_wrapper .appointment-form-row {
    position: relative;
    height: 125px;
  }
  #appointment_form_wrapper .appointment-form-row:first-of-type {
    min-height: 85px;
    max-height: 85px;
    height: 85px;
    margin-bottom: 15px;
  }
  #appointment_form_wrapper .appointment-left {
    min-width: 60%;
    max-width: 60%;
    width: 60%;
    padding-left: 0 !important;
    position: absolute;
    left: 0;
    top: 0;
  }
  #appointment_form_wrapper .appointment-right {
    min-width: 35%;
    max-width: 35%;
    width: 35%;
    padding-left: 0 !important;
    position: absolute;
    right: 0;
    top: 0;
  }
  #appointment_form_wrapper .appointment-right.appointment-submit-row {
    text-align: end;
    line-height: 125px;
    min-height: 100%;
    max-height: 100%;
    height: 100%;
  }
  #appointment_form_wrapper .appointment-right.appointment-submit-row button {
    min-height: 50%;
    max-height: 50%;
    height: 50%;
    margin-right: 10%;
  }
}
@media screen and (min-width: 768px) {
  #appointment_form_container {
    grid-template-columns: calc(100% - 240px - 1.4rem) 220px;
  }
  #appointment_submit_row {
    min-height: 100px;
    max-height: 100px;
    height: 100px;
    line-height: 100px;
    text-align: end;
  }
  #appointment_submit_row button {
    min-height: 50%;
    max-height: 50%;
    height: 50%;
  }
  #appointment_form_wrapper .form-widget {
    margin-left: 0 !important;
    padding-left: 0 !important;
    text-align: left;
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
  #appointment_form_wrapper form textarea {
    margin-left: 0 !important;
    min-width: 90%;
    max-width: 90%;
    width: 90%;
  }
  #appointment_form_wrapper .appointment-form-row {
    position: relative;
    height: 125px;
  }
  #appointment_form_wrapper .appointment-form-row:first-of-type {
    min-height: 85px;
    max-height: 85px;
    height: 85px;
    margin-bottom: 15px;
  }
}
#services_section_content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  justify-content: center;
}
#services_section_content .service-card {
  border-radius: 1rem;
  text-align: center;
}
#services_section_content .service-card .service-icon {
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
  aspect-ratio: 1/1;
  min-width: 96px !important;
  max-width: 128px !important;
  width: 80% !important;
}
#services_section_content .service-card .service-title {
  font-weight: bold;
  color: var(--section-title-color);
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
  font-size: 1.3rem;
}
#services_section_content .service-card hr {
  border: 0 none;
  height: 3px;
  width: 100px;
  margin: 0 auto;
  background-color: var(--section-title-color);
  margin-bottom: 1rem;
  margin-top: 1rem;
}
#services_section_content .service-card .service-description {
  font-size: 1rem;
  color: var(--forground-color);
  padding: 0 1rem 1rem 1rem;
}

@media screen and (max-width: 576px) {
  #services_section_content .service-description {
    display: none;
  }
}
@media screen and (min-width: 577px) {
  #services_section_content .service-description {
    margin: 0 15px 15px 15px !important;
  }
}
.product-item-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  padding-top: 100%;
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}
.product-item-wrapper .product-item, .product-item-wrapper .product-image, .product-item-wrapper .product-desc {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.product-item-wrapper .product-image {
  padding: 0 !important;
  margin: 0 !important;
}
.product-item-wrapper .product-image img {
  margin: 0 !important;
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}
.product-item-wrapper .product-desc {
  overflow: hidden;
  color: #fff;
  background-color: transparent;
}

.product-desc .product-desc-layout {
  position: relative;
  min-height: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  width: 100% !important;
}
.product-desc .product-title, .product-desc .product-info {
  position: absolute;
  left: 0;
  right: 0;
}
.product-desc .product-title {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  display: none;
}
.product-desc .product-info {
  bottom: 1rem;
  top: unset;
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: center;
  font-size: 0.9rem;
}
.product-desc .product-info .user-logged {
  cursor: pointer;
}
.product-desc .product-info .info-counter {
  margin-left: 0.4rem;
}
.product-desc:hover {
  background-color: rgba(2, 2, 2, 0.7);
}
.product-desc:hover .product-title {
  display: block;
}

.product-item--enter, .product-item--leave {
  animation-fill-mode: forwards;
}

.product-item--enter {
  transform: translate3d(0, -100%, 0);
} /**/
#product_latest_work_container_wrapper {
  margin-bottom: 1.5rem;
}

#product_latest_work_container {
  display: grid;
  gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
  margin-bottom: 1rem;
}

#product_latest_work_view_all {
  margin-top: 1.5rem !important;
}

@media screen and (max-width: 480px) {
  #product_latest_work_container {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .product-item:nth-last-child(-n+2) {
    display: none;
  }
}
@media screen and (min-width: 480px) and (max-width: 480px) {
  #product_latest_work_container {
    grid-template-columns: repeat(4, 1fr);
  }
  .product-main-item {
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}
/*
@media screen and (min-width : $mediumDeviceBreakPoint)
{}*/
@media screen and (min-width: 992px) {
  #product_latest_work_container {
    grid-template-columns: repeat(5, 1fr);
  }
  .product-main-item {
    grid-column-end: span 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  }
}
.team-nav {
  position: absolute;
  z-index: 500;
  border: 1px solid transparent;
  border-radius: 50% !important;
  min-height: 36px !important;
  max-height: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
  min-width: 36px !important;
  max-width: 36px !important;
  width: 36px !important;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.team-nav#employee_team_navigation_previous {
  left: 0;
  right: unset;
}
.team-nav#employee_team_navigation_next {
  left: unset;
  right: 0;
}

#employee_team_container_wrapper {
  min-height: 350px;
  max-height: 350px;
  height: 350px;
  margin-bottom: 50px !important;
  position: relative;
  overflow: hidden;
}

#employee_team_container {
  z-index: 1;
  min-height: 350px;
  max-height: 350px;
  height: 350px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
#employee_team_container .agency-team-member h5, #employee_team_container .agency-team-member p {
  text-align: center;
}
#employee_team_container .agency-team-member .team-social-network {
  min-height: 36px;
  max-height: 36px;
  height: 36px;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  line-height: 36px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  overflow: hidden;
}
#employee_team_container .agency-team-member .team-social-network .social-link {
  min-height: 36px;
  max-height: 36px;
  height: 36px;
  min-width: 36px;
  max-width: 36px;
  width: 36px;
  text-align: center;
}
#employee_team_container .agency-team-member .team-social-network .social-link .social-icon-wrapper {
  min-height: 28px;
  max-height: 28px;
  height: 28px;
  min-width: 28px;
  max-width: 28px;
  width: 28px;
  text-align: center;
  line-height: 28px;
  padding: 0 !important;
  margin: 2px !important;
}
#employee_team_container .agency-team-member .team-social-network .social-link .social-icon-wrapper .social-icon {
  min-height: 28px;
  max-height: 28px;
  height: 28px;
  min-width: 28px;
  max-width: 28px;
  width: 28px;
}
#employee_team_container .agency-team-member .team-member-read-more {
  text-align: center;
}
#employee_team_container .team-member-thumb, #employee_team_container .team-member-thumb img {
  min-height: 140px;
  max-height: 140px;
  height: 140px;
  line-height: 140px;
  min-width: 140px;
  max-width: 140px;
  width: 140px;
  margin: 0 auto;
  border-radius: 50%;
}
#employee_team_container .team-member-thumb {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
#employee_team_container .team-member-thumb img {
  text-align: center;
  font-size: 6rem;
}

#how_we_work_container {
  display: grid;
  justify-content: center;
  gap: 1rem;
}
#how_we_work_container .carousel-inner {
  width: 100%;
  height: 100%;
}
#how_we_work_container .carousel-inner .carousel-item {
  width: 100%;
  height: 100%;
}
#how_we_work_container .carousel-inner .carousel-item img {
  width: 100%;
  height: 100%;
}

#employee_ability_items_wrapper .employee-ability-item {
  position: relative;
  margin-bottom: 20px;
  min-height: 120px;
}
#employee_ability_items_wrapper .employee-ability-icon {
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid grey;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  overflow: hidden;
}
#employee_ability_items_wrapper .employee-ability-icon img {
  width: 76px;
  height: 76px;
}
#employee_ability_items_wrapper .employee-ability-wrapper .ability-title {
  position: absolute;
  left: 86px;
  top: 6px;
  width: calc(100% - 86px);
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--section-title-color);
}
#employee_ability_items_wrapper .employee-ability-wrapper .ability-description {
  position: absolute;
  left: 0;
  top: 36px;
  width: 100%;
  text-indent: 84px;
  display: block;
  display: -webkit-box;
  font-size: 1rem;
  line-height: 2rem;
  padding: 3px !important;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  color: var(--forground-color);
}
#employee_ability_items_wrapper .employee-ability-wrapper hr {
  width: 120px;
  border: none;
  height: 3px;
  position: absolute;
  left: 86px;
  top: 12px;
  margin-top: 1.5rem;
  background: var(--section-title-color);
}

@media screen and (max-width: 480px) {
  .employee-ability-item {
    margin-bottom: 1rem;
    min-height: 140px;
    max-height: 140px;
    height: 140px;
  }
  .employee-ability-item:last-of-type {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 576px) {
  #how_we_work_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}
@media screen and (min-width: 576px) and (max-width: 768px) {
  .employee-ability-wrapper .ability-description {
    display: none !important;
  }
}
@media screen and (min-width: 768px) {
  .employee-ability-wrapper .ability-description {
    display: block;
  }
}
#help_you_grow_your_business_section {
  min-height: 200px !important;
  max-height: 200px !important;
  height: 200px !important;
}
#help_you_grow_your_business_section .section-container {
  border-radius: 10px;
  background-size: cover;
  background-image: url("../../../images/help_your_business.jpg");
}
#help_you_grow_your_business_section .section-content {
  position: relative;
  background-color: rgba(14, 14, 14, 0.4);
  height: 100% !important;
}
#help_you_grow_your_business_section .section-content h4, #help_you_grow_your_business_section .section-content p {
  position: absolute;
  transform: rotate(-3deg);
  font-family: "Dancing Script", cursive;
}
#help_you_grow_your_business_section .section-content h4 {
  font-size: 2rem;
  color: var(--section-title-color);
}
#help_you_grow_your_business_section .section-content p {
  font-size: 1.4rem;
  color: var(--forground-color);
}

#help_you_grow_your_business_content {
  position: absolute;
  border-left: 8px solid rgb(27, 155, 255);
  left: 50px;
  height: 150px;
}

#help_you_grow_your_business_register {
  position: absolute;
}
#help_you_grow_your_business_register .button {
  padding: O !important;
  margin: 0 !important;
  background-color: var(--section-title-color);
  color: var(--forground-color);
  border: transparent;
  border-radius: 8px;
  font-size: 1.4rem;
}
#help_you_grow_your_business_register .button:hover {
  transform: scale(0.98);
  color: whitesmoke;
}

@media screen and (max-width: 576px) {
  #help_you_grow_your_business_section .section-content h4 {
    left: 100px;
  }
  #help_you_grow_your_business_section .section-content p {
    display: none;
  }
  #help_you_grow_your_business_section .section-content .button {
    display: block;
    padding: 12px;
  }
  #help_you_grow_your_business_register {
    top: 100px;
    right: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  #help_you_grow_your_business_section .section-content h4 {
    left: 100px;
  }
  #help_you_grow_your_business_section .section-content p {
    display: none;
  }
  #help_you_grow_your_business_register {
    top: 100px;
    right: 30px;
  }
  #help_you_grow_your_business_register .button {
    padding: 12px;
  }
}
@media screen and (min-width: 767px) {
  #help_you_grow_your_business_section .section-content h4, #help_you_grow_your_business_section .section-content p {
    left: 70px;
  }
  #help_you_grow_your_business_section .section-content h4 {
    width: 60%;
    min-width: 60%;
    max-width: 60%;
    top: 1rem;
  }
  #help_you_grow_your_business_section .section-content p {
    display: block;
    width: 70%;
    min-width: 70%;
    max-width: 70%;
    top: 60px;
  }
  #help_you_grow_your_business_register {
    width: 150px;
    height: 120px;
    right: 60px;
    top: 35px;
  }
  #help_you_grow_your_business_register .button {
    height: 90px;
    line-height: 90px;
  }
}